// eslint-disable-next-line import/prefer-default-export
import { DAY_MONTH_YEAR, DAY_MONTH_YEAR_SLASH } from '@/libs/filterDate'

export const tableBiodata = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'skill',
    label: 'Skill Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableSelected = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'education',
    label: 'Pendidikan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'year_experience',
    label: 'Pengalaman Kerja',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      width: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'quiz',
    label: 'Kuisioner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableExperience = [
  {
    key: 'name',
    label: 'Nomor & Nama Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'pl-1 text-black',
    class: 'p-0',
    thClass: 'pl-1 py-1',
  },
  {
    key: 'skill_name',
    label: 'Skill Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'text-black',
    class: 'p-0',
    thClass: 'py-1',
  },
  {
    key: 'business_sector_name',
    label: 'Sektor Bisnis',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'text-black',
    class: 'p-0',
    thClass: 'py-1',
  },
  {
    key: 'start_date',
    label: 'Tanggal Mulai',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'text-black',
    class: 'p-0',
    thClass: 'py-1',
    formatter: value => DAY_MONTH_YEAR_SLASH(value),
  },
  {
    key: 'end_date',
    label: 'Tanggal Usai',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'text-black',
    class: 'p-0',
    thClass: 'py-1',
    formatter: value => (!value ? 'Masih Bekerja' : DAY_MONTH_YEAR_SLASH(value)),
  },
  {
    key: 'work_duration',
    label: 'Lama Bekerja',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'text-black',
    class: 'p-0',
    thClass: 'py-1',
    formatter: value => value.replace('0 Bulan', ''),
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '5px',
    },
    tdClass: 'text-black',
    class: 'p-0',
    thClass: 'py-1 text-center',
  },
]

export const tableHired = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'skill_name',
    label: 'Skill Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'partner_name',
    label: 'Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'last_talent_lead',
    label: 'Talent Lead',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'rating',
    label: 'Rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'show_talent',
    label: 'Show Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black text-center',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableHiredMultiple = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'skill_name',
    label: 'Skill Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'partner',
    label: 'Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'talent_lead',
    label: 'Talent Lead',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'rating',
    label: 'Rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableNonJob = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'skill_name',
    label: 'Skill Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'last_talent_lead',
    label: 'Talent Lead Terakhir',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'rating',
    label: 'Rating',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'show_talent',
    label: 'Show Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black text-center',
    class: 'p-1 text-center',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableTalentOff = [
  {
    key: 'name',
    label: 'Nama talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'skill_name',
    label: 'Skill Role',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'sk',
    label: 'surat Keterangan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'off_status',
    label: 'Status Off',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const filterRatingOptions = [
  { star: 5, value: 5 },
  { star: 4, value: 4 },
  { star: 3, value: 3 },
  { star: 2, value: 2 },
  { star: 1, value: 1 },
]

export const filterNonjobOptions = [
  { text: 'Terlama', value: 'oldest' },
  { text: 'Paling Lambat', value: 'newest' },
]

export const terminationOptions = [
  { text: 'Resign', value: 'resign' },
  { text: 'Diberhentikan', value: 'dismissed' },
]

export const listPlatform = [
  {
    platform_name: 'Shopee',
    value: null,
    value_type: '%',
  },
  {
    platform_name: 'Tokopedia',
    value: null,
    value_type: 'menit',
  },
  {
    platform_name: 'Lazada',
    value: null,
    value_type: '%',
  },
  {
    platform_name: 'Blibli',
    value: null,
    value_type: '%',
  },
  {
    platform_name: 'Bukalapak',
    value: null,
    value_type: 'rate',
  },
]
